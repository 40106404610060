import {
  BuildCircle,
  CheckCircle,
  Dangerous,
  Error,
  InfoRounded,
  NewReleases,
  SvgIconComponent,
  Warning,
} from "@mui/icons-material";
import { IconProps } from "@mui/material";
import React, { memo } from "react";
import { Severity } from "../../types/notifications";

interface SeverityProps {
  severity: Severity;
  fontSize?: IconProps["fontSize"];
}

interface SeverityConfig {
  Icon: SvgIconComponent;
  color: string;
}

type SeverityMap = Record<Severity, SeverityConfig>;

const severityMap: SeverityMap = {
  SUCCESS: { Icon: CheckCircle, color: "#2E7D32" },
  ERROR: { Icon: Error, color: "#D32F2F" },
  DANGER: { Icon: NewReleases, color: "#D32F2F" },
  WARNING: { Icon: Warning, color: "#ED6C02" },
  INFO: { Icon: InfoRounded, color: "#2B5DC6" },
  SYSTEM: { Icon: BuildCircle, color: "#2B5DC6" },
  CRITICAL: { Icon: Dangerous, color: "#D32F2F" },
};

const SeverityIndicator: React.FC<SeverityProps> = ({
  severity,
  fontSize = "medium",
}) => {
  const severityConfig = severityMap[severity];

  if (!severityConfig) {
    return null;
  }

  const { Icon, color } = severityConfig;

  return <Icon fontSize={fontSize} htmlColor={color} />;
};

export default memo(SeverityIndicator);
