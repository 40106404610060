import sanitizeHtml from "sanitize-html";
import { createContext, FC, useContext, useEffect, useState } from "react";
import {
  Notification as INotification,
  NotifyEventsContextType,
} from "../types/notifications";
import { usePortalProvider } from "../providers/portal-provider";
import { toast, ToastContainer } from "react-toastify";
import { Message } from "../components/Message/Message";

if (typeof window !== "undefined") {
  require("react-toastify/dist/ReactToastify.css");
}

const sanitizeMessage = (data: string): INotification => {
  const sanitized = JSON.parse(data);
  sanitized.title = sanitizeHtml(sanitized.title);
  sanitized.body = sanitizeHtml(sanitized.body);
  return sanitized;
};

export const NotifyEventsContext =
  createContext<NotifyEventsContextType | null>(null);

export const NotifyEventsProvider: FC = ({ children }) => {
  const [eventSource, setEventSource] = useState<EventSource | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [permission, setPermission] = useState<NotificationPermission>();

  const { env, accessToken } = usePortalProvider();

  const onMessage = (cb: (message: MessageEvent) => any) => {
    eventSource?.addEventListener("message", cb);
    return () => {
      eventSource?.removeEventListener("message", cb);
    };
  };

  const onOpen = (evt: MessageEvent) => {};

  const presentNotification = (evt: MessageEvent) => {
    if (permission !== "granted") return;

    try {
      const message: INotification = sanitizeMessage(evt.data);
      const options = {
        closeButton: false,
        ariaLabel: "Mensagem recebida",
        hideProgressBar: true,
      };
      toast(<Message message={message} />, options);
    } catch (e) {
      console.error("Erro ao processar notificação:", error);
    }
  };

  const onError = (evt: MessageEvent) => {
    const errorMessage =
      evt instanceof ErrorEvent ? evt.message : "Erro desconhecido";
    setError(new Error(errorMessage));
  };

  useEffect(() => {
    if (!accessToken) return;

    const url = `${NOTIFY_API_URL}/events/onMessage`;
    const source = new EventSource(`${url}?token=${accessToken}`);
    source.addEventListener("open", onOpen);
    source.addEventListener("error", onError);
    source.addEventListener("message", presentNotification);
    setEventSource(source);

    return () => {
      source.close();
      source.removeEventListener("open", onOpen);
      source.removeEventListener("error", onError);
      source.removeEventListener("message", presentNotification);
    };
  }, [accessToken, env]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!accessToken) return;

    if (!("Notification" in window)) {
      console.warn("Este browser não tem suporte para Notification");
      return;
    }

    Notification.requestPermission().then((permission) => {
      console.log({ permission });
      setPermission(permission);
    });
  }, [accessToken]);

  return (
    <NotifyEventsContext.Provider
      value={{
        eventSource,
        onMessage,
        error,
      }}
    >
      {children}
      <ToastContainer
        position="bottom-right"
        autoClose={60_000 * 2}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </NotifyEventsContext.Provider>
  );
};

export const useNotifyEvents = () => {
  const context = useContext(NotifyEventsContext);
  return context;
};
