import React, { useEffect, useMemo } from "react";
import "./App.css";
import { Switch, Route, useLocation } from "react-router-dom";
import { Routes } from "./routes";
import { usePageTrackingGA, useWebgate } from "@xvia/webgate-connect";
import { PopupProvider } from "./providers/PopupProvider";

function App() {
    const { accessToken, portalConnect, serverData, userInfo } = useWebgate();

  const location = useLocation();

  useEffect(() => {
    console.log("webgate: ", userInfo);
  }, [userInfo]);

  usePageTrackingGA(location);

  const routes = useMemo(
    () =>
      Object.keys(Routes).map((path) => {
        const RouteComponent = Routes[path];
        return (
          <Route key={path} path={path}>
            <RouteComponent />
          </Route>
        );
      }),
    []
  );
  return (
    <PopupProvider>
      <main>
        <Switch>{routes}</Switch>
      </main>
    </PopupProvider>
  );
}

export default App;
