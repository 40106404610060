import { Notification as INotification } from "../../types/notifications";
import { FC, useMemo } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ToastContentProps } from "react-toastify";
import SeverityIndicator from "./SeverityIndicator";
import { OpenInNew } from "@mui/icons-material";

type MessageProps = Partial<ToastContentProps> & {
  message: INotification;
};
export const Message: FC<MessageProps> = ({ message, closeToast }) => {
  const history = useHistory();
  const openMessage = () => {
    history.push(`/app/painel-cidadao/notifications/${message.messageId}`);
    closeToast?.();
  };

  const bodyMessage = useMemo(() => {
    if (message.body.length < 100) return message.body;
    return `${message.body.slice(0, 100)}...`;
  }, [message]);

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <SeverityIndicator severity={message.level} fontSize="large" />
          <Typography gutterBottom variant="body1" component="div">
            {message.title}
          </Typography>
        </Stack>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {bodyMessage}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button color="primary" endIcon={<OpenInNew />} onClick={openMessage}>
          Abrir mensagem
        </Button>
        <Button color="inherit" onClick={closeToast}>
          Ignorar
        </Button>
      </Stack>
    </Stack>
  );
};
